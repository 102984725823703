import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import classes from './styles.module.css';

const AlertModal = ({
  open,
  onClose,
  title,
  message,
  CloseButtonText,
  translations = [],
  ...props
}) => {
  return (
    <Dialog
      open={open}
      scroll='body'
      maxWidth='xs'
      onClose={onClose}
      disableEscapeKeyDown
      className={classes['alert-modal']}
      {...props}
    >
      {title && <DialogTitle className={classes['title']}>{title}</DialogTitle>}
      {message && <DialogContent>{message}</DialogContent>}
      <DialogActions>
        <button onClick={onClose} className={classes['close-button']}>
          {translations['Close'] || 'Close'}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
