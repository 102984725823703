import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import '../home.css';
import Paper from '@material-ui/core/Paper';
import eventImg from '../../../public/img/event.png';
import userImg from '../../../public/img/icn_user.svg';
import { useTimer } from 'react-timer-hook';
import { HomeService } from '../Service/homeService';
import PIInfo from '../../shared/PIInfo';

import { Dialog } from '@mui/material';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@mui/material/Tooltip';
import AlertModal from '../../shared/Alert';
import Thumbnail from '../../shared/Thumbnail';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

// Countdown Timer
const MyTimer = ({ expiryTimestamp, translation }) => {
    // eslint-disable-next-line no-unused-vars
    const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
    return (
        <div className='timerAreaSec'>
            <span className='timerSec'>{days} <span className='timerVal'>{translation['Days'] ? translation['Days'] : 'Days'}</span> </span><span className='dots'>:</span>
            <span className='timerSec'>{hours} <span className='timerVal'>{translation['Hours'] ? translation['Hours'] : 'Hours'}</span> </span><span className='dots'>:</span>
            <span className='timerSec'>{minutes} <span className='timerVal'>{translation['Minutes'] ? translation['Minutes'] : 'Minutes'}</span> </span>
            {/* <span className='timerSec'>{seconds} <span className='timerVal'>Seconds</span> </span> */}
        </div>
    );
}

const LiveEvent = (props) => {
    const [isEventRegistered, SetIsEventRegistered] = React.useState(false);
    const titleElementRef = React.useRef();

    const isTitleTruncated = titleElementRef.current && titleElementRef.current.scrollHeight > titleElementRef.current.clientHeight;

    let eventData = props.event;
    const event_id = eventData.event_id;
    const event_title = eventData.title;
    const event_category1 = eventData.category_1_id;

    let selectorValue = useSelector(state => state.session);
    React.useEffect(() => {
        if (selectorValue) {
            if (selectorValue.sessionValue.payload) {
                if (selectorValue.sessionValue.payload.user) {
                    if (selectorValue.sessionValue.payload.user) {
        
                    }
                }
            }
        }
    }, [selectorValue]);

    React.useEffect(() => {
        let homeService = new HomeService();
        homeService.GetLiveEventRegistrations(event_id).then((data) => {
            if(data.registrants.length) {
                for(let i=0;i<data.registrants.length;i++) {
                    const user_info = JSON.parse(localStorage.getItem('user_info'));
                    if(data.registrants[i].email === user_info.email) {
                        SetIsEventRegistered(true);
                        break;
                    }
                }
            }
        }).catch((err) => {  })
    }, [selectorValue]);

    const event_desc = eventData.promotionalsummary;
    const event_speakers = eventData.speakers_id;
    let event_start = eventData.goodafter;
    //event_start = (new Date(event_start).getTime() + 15*60000);
    const event_url = eventData.audienceurl+`?mode=preview&email=${selectorValue?.sessionValue?.payload?.user?.user_email}`;
    const local = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US';
    const event_start_date = new Date(event_start).toLocaleString(local, {
        hourCycle: 'h23',
        dateStyle: 'full',
        timeStyle: 'medium',
    });
    const event_start_date_short_form = new Date(event_start).toLocaleString(local, {
        hourCycle: 'h23',
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
    const event_duration = eventData.eventduration;
    
    const event_end_date_js = new Date(event_start);
    let year = event_end_date_js.getFullYear();
    let month = event_end_date_js.getMonth() + 1;
    let dt = event_end_date_js.getDate();
    let seconds = event_end_date_js.getSeconds();
    let minutes = event_end_date_js.getMinutes();
    let hour = event_end_date_js.getHours();
    if (dt < 10) { dt = '0' + dt; }
    if (month < 10) { month = '0' + month; }
    const time = new Date(year + '/' + month + '/' + dt + ' ' +hour +':'+minutes+':'+seconds);
   
    const [popupState, setPopupState] = React.useState(false);
    const [msgPopupState, setMsgPopupState] = React.useState(false);
    const [popupMsg, setPopupMsg] = React.useState("");

    // Handle Register Button
    const handleRegisterBtn = () => {
        if (localStorage.getItem('HCP') === "false") {
            let homeService = new HomeService();
            homeService.RegisterLiveEvent(event_id).then(() => {
                SetIsEventRegistered(true);
                setPopupState(false);
            }).catch(() => {  })
        } else {
            setPopupMsg("Only HCP can register Event.");
            setMsgPopupState(true);
        }
    };

    const userThumbnail = useMemo(() => {
        const attachment = eventData.avatar_attachment_id && eventData.avatar_attachment_id.trim();
        return attachment ? `Event/Image/${attachment}`: null;
    }, [eventData.avatar_attachment_id]);

    return (
        <>
            <AlertModal
                open={msgPopupState}
                onClose={() => setMsgPopupState(false)}
                message={popupMsg}
                translations={props.translations}
            />
            <Dialog open={popupState} scroll='body' maxWidth='md' TransitionComponent={Transition} onClose={() => setPopupState(false)} disableEscapeKeyDown className='live_event-modal'>
                <DialogTitle className='modal-top'>
                    <button className='closeModal' onClick={() => setPopupState(false)}></button>
                </DialogTitle>
                <DialogContent className='modal-middle'>
                    <Grid container className='event_details'>
                        <Grid item xs={12} lg={12}>
                            <h2 className='event_title'>{event_title}</h2>
                            <h4 className='event_date'>{event_start_date}</h4>
                            <h4 className='event_duration'>{props.translations['Duration'] ? props.translations['Duration'] : 'Duration'}: {event_duration} {props.translations['Minutes'] ? props.translations['Minutes'] : 'Minutes'}</h4>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <h4 className='event_info_head'>{props.translations['Event Description'] ? props.translations['Event Description'] : 'Event Description'}</h4>
                            <div className='event_desc'>
                                <div dangerouslySetInnerHTML = {{__html:event_desc}}></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <h4 className='event_info_head'>{props.translations['Speakers'] ? props.translations['Speakers'] : 'Speakers'}</h4>
                            <ul className='event_speakers'>
                                {event_speakers && event_speakers.length > 0 ? event_speakers.map((speaker) => {
                                    return (
                                        speaker != null ?
                                            <li key={speaker.id}>
                                                <div className='speaker_icon'><img src={userImg} alt="speaker_icon" height="35px"/></div>
                                                <div className='speaker_name'>{speaker.name}</div>
                                            </li> : null
                                    )
                                }) : ""}
                            </ul>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='modal-bottom text-center'>
                    {isEventRegistered ?
                        <button type="button" className="modalBtn" onClick={() => window.open(event_url)}>{props.translations['JOIN NOW'] ? props.translations['JOIN NOW'] : 'Join Now'}</button> :
                        <button type="button" className="modalBtn" onClick={() => handleRegisterBtn()}>{props.translations['REGISTER'] ? props.translations['REGISTER'] : 'Register'}</button>
                    }
                </DialogActions>
            </Dialog>

            {props.slides ? 
                <Grid container direction="column" className={props.slides === 3 ? 'sOuterlg' : 'sOuter'}>
                    <Thumbnail content={{content_type: 'event', duration: eventData.eventduration * 60, thumbnail: eventImg, user_thumbnail: userThumbnail}}/>
                    <Grid container wrap="nowrap">
                        <Grid item xs>
                            <Paper className='liveTag'>{props.translations['Live Event'] ? props.translations['Live Event'] : 'Live Event'}</Paper>
                        </Grid>
                        <Grid item className='timerArea'>
                            <MyTimer expiryTimestamp={time} translation={props.translations}/>
                        </Grid>
                    </Grid>
                    <Tooltip title={isTitleTruncated ? event_title : ''} placement='bottom'>
                        { isEventRegistered ? 
                            <Paper ref={titleElementRef} className='cont' onClick={() => window.open(event_url)}>{event_title}</Paper> :
                            <Paper ref={titleElementRef} className='cont' onClick={() => handleRegisterBtn()}>{event_title}</Paper> 
                        }
                    </Tooltip>
                    <Grid container direction="row">
                        <Grid item xs={12} className='cont-startdate-home hide-xs'>{event_start_date}</Grid>
                        <Grid item xs={12} className='cont-startdate-home show-xs'>{event_start_date_short_form}</Grid>
                    </Grid>
                    <Grid className="cont-piinfo-home">
                        {event_category1?.length ? <PIInfo category1Ids={event_category1} /> : ""}
                    </Grid>
                    <Grid container style={{ flexGrow: 1 }} direction="row" justifyContent="flex-end" alignItems="flex-end">
                        <Grid item xs={12} className='BtnSec'>
                            <button className='liveBtn' onClick={() => setPopupState(true)}>{props.translations['More Details'] ? props.translations['More Details'] : 'More Details'}</button>
                            {isEventRegistered ?
                                <button className='liveBtn' onClick={() => window.open(event_url)}>{props.translations['JOIN NOW'] ? props.translations['JOIN NOW'] : 'JOIN NOW'}</button> :
                                <button className='liveBtn' onClick={() => handleRegisterBtn()}>{props.translations['REGISTER'] ? props.translations['REGISTER'] : 'REGISTER'}</button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            : 
                <Grid style={{ overflow: 'hidden', marginBottom: '10px' }}>
                    <Grid container>
                        <Grid item xs={12} lg={2} sm={4} md={3}>
                            <Thumbnail content={{content_type: 'event', duration: eventData.eventduration * 60, thumbnail: eventImg, user_thumbnail: userThumbnail}}/>
                        </Grid>
                        <Grid item xs={12} lg={10} sm={8} md={9} className='content-list-item-wrapper'>
                            <Grid><Paper className={'liveTag-content'}>{props.translations['Live Event'] ? props.translations['Live Event'] : 'Live Event'}</Paper></Grid>
                            { isEventRegistered ? 
                                <Grid className='cont-title clickableLink' onClick={() => window.open(event_url)} >{event_title}</Grid> :
                                <Grid className='cont-title clickableLink' onClick={() => handleRegisterBtn()} >{event_title}</Grid> 
                            }
                            <Grid className='cont-startdate'>{event_start_date}</Grid>
                            <Grid className="cont-piinfo">
                                {event_category1?.length ? <PIInfo category1Ids={event_category1} /> : ""}
                            </Grid>
                            <Grid item xs={12} lg={8} className='BtnSec LeftAlign margintop margintop2'>
                                <button className='liveBtn' onClick={() => setPopupState(true)}>{props.translations['More Details'] ? props.translations['More Details'] : 'More Details'}</button>
                                {isEventRegistered ?
                                    <button className='liveBtn' onClick={() => window.open(event_url)}>{props.translations['JOIN NOW'] ? props.translations['JOIN NOW'] : 'JOIN NOW'}</button> :
                                    <button className='liveBtn' onClick={() => handleRegisterBtn()}>{props.translations['REGISTER'] ? props.translations['REGISTER'] : 'REGISTER'}</button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
}
export default LiveEvent